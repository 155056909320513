import { render, staticRenderFns } from "./ConfigView.vue?vue&type=template&id=a4999a06&scoped=true&"
import script from "./ConfigView.vue?vue&type=script&lang=js&"
export * from "./ConfigView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4999a06",
  null
  
)

export default component.exports