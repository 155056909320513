<template>
  <div>
    <b-card v-if="agency">
      <b-tabs
        content-class="mt-3 mb-2"
        justified
      >
        <b-tab
          title="Agencia"
          active
        >
          <b-row>
            <b-col>
              <component
                :is="logo.getComponent()"
                :options="logo.getOptions()"
                :field="logo"
              />
              <b-form-group
                label="Nombre de la agencia"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="agency.name"
                  placeholder="Nombre de la agencia"
                />
              </b-form-group>

              <b-form-group
                label="Texto del teléfono"
                label-for="phone_text"
              >
                <b-form-input
                  id="phone_text"
                  v-model="agency.phone_text"
                  placeholder="Texto del teléfono"
                />
              </b-form-group>

              <b-form-group
                label="Correo de contacto"
                label-for="contact_mail"
              >
                <b-form-input
                  id="contact_mail"
                  v-model="agency.contact_mail"
                  placeholder="Correo de contacto"
                />
              </b-form-group>

              <b-form-group
                label="Facebook"
                label-for="facebook"
              >
                <b-form-input
                  id="facebook"
                  v-model="agency.facebook"
                  placeholder="Facebook"
                />
              </b-form-group>

              <b-form-group
                label="Instagram"
                label-for="instagram"
              >
                <b-form-input
                  id="instagram"
                  v-model="agency.instagram"
                  placeholder="Instagram"
                />
              </b-form-group>

              <div class="row">
                <div class="col-6">
                  <b-form-group
                    label="Color primario"
                    label-for="primary_color"
                  >
                    <b-form-input
                      id="primary_color"
                      v-model="agency.primary_color"
                      type="color"
                      placeholder="Color primario"
                    />
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    label="Color secundario"
                    label-for="secondary_color"
                  >
                    <b-form-input
                      id="secondary_color"
                      v-model="agency.secondary_color"
                      type="color"
                      placeholder="Color secundario"
                    />
                  </b-form-group>

                </div>
              </div>

            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Notificaciones">
          <p>
            Listado de correos electrónicos a los que se enviarán las notificaciones como copia oculta
          </p>
          <!-- Lista de inputs para correos que se rellena de agency.email_configs !-->

          <b-input-group
            v-for="(email, index) in agency.email_configs"
            :key="index"
            label="Correo de notificación"
            label-for="email"
            class="mb-2"
          >
            <b-form-input
              v-model="email.email"
              placeholder="Correo de notificación"
            />
            <!-- Botón para eliminar suffix input ggroip!-->
            <b-button
              variant="danger"
              @click="deleteEmail(index)"
            >
              Eliminar
            </b-button>

          </b-input-group>

          <!-- button group !-->
          <b-button-group>
            <b-button
              variant="success"
              @click="addEmail"
            >
              Agregar
            </b-button>

          </b-button-group>

        </b-tab>
        <b-tab title="Correo electrónico">
          <p>Configuración para envió de notificaciones por correo electrónico</p>
          <!-- inputs para agency.sendgrid_api_key y agency.sendgrid_from_mail !-->
          <b-form-group
            label="API Key de Sendgrid"
            label-for="sendgrid_api_key"
          >
            <b-form-input
              id="sendgrid_api_key"
              v-model="agency.sendgrid_api_key"
              placeholder="API Key de Sendgrid"
            />
          </b-form-group>
          <b-form-group
            label="Correo de envío"
            label-for="sendgrid_from_mail"
          >
            <b-form-input
              id="sendgrid_from_mail"
              v-model="agency.sendgrid_from_mail"
              placeholder="Correo de envío"
            />
          </b-form-group>

          <p>Plantillas de Sendgrid</p>
          <!-- Tabla con lista de notificaciones de sistema systemTemplates.getAttr('id') y su descripción abajo  !-->
          <b-table
            :items="systemTemplates"
            :fields="fields"
            striped
          >
            <template
              v-slot:cell(notification)="{ item }"
            >
              <div style="width: 250px">
                <p class="m-0 font-weight-bold">
                  {{ item.getAttr('name') }}
                </p>
                <p
                  class="m-0"
                  style="font-size: 12px"
                >
                  {{ item.getAttr('description') }}
                </p>
              </div>
            </template>
            <template
              v-slot:cell(email)="{ item }"
            >
              <!-- Input para correo  !-->
              <b-form-input
                v-model="templates[item.getAttr('id')]"
                placeholder="Correo de notificación"
              />

            </template>
          </b-table>

        </b-tab>
      </b-tabs>

      <b-button
        variant="primary"
        @click="save"
      >
        Guardar
      </b-button>
    </b-card>
  </div>
</template>

<script>

import {
  BTab, BTabs, BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BButtonGroup, BInputGroup, BTable,
} from 'bootstrap-vue'
import GeneralService from '@/dc-it/services/GeneralService'
import { ref } from '@vue/composition-api'
import Multimedia from '@/dc-it/models/Multimedia'
import SystemTemplate from '@/dc-it/models/SystemTemplate'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import config from '@/router/config'

export default {
  name: 'ConfigView',
  components: {
    BCard, BTabs, BTab, BRow, BCol, BFormGroup, BFormInput, BButton, BButtonGroup, BInputGroup, BTable,
  },
  methods: {
    save() {
      const logo = this.logo.getValue()[0]
      this.agency.logo = logo.toJSON()
      const templates = []
      Object.getOwnPropertyNames(this.templates).forEach(key => {
        if (key === '__ob__') return
        templates.push({
          system_template_id: key,
          sendgrid_template_id: this.templates[key],
        })
      })
      this.agency.agency_templates = templates

      GeneralService.getInstance().save('agency', this.agency).then(response => {
        if (response.result === 'success') {
          this.$bvToast.toast('Guardado correctamente', {
            title: 'Guardado',
            variant: 'success',
          })

          this.$store.dispatch('app/getAgencyData', config.agency)
        } else if (response.errors) {
          let error = ''
          Object.getOwnPropertyNames(response.errors).forEach(key => {
            error += (`${response.errors[key][0]}`)
          })
          this.$bvToast.toast(`Error al guardar,${error}`, {
            title: 'Error',
            variant: 'danger',
          })
        }
      })
    },
    deleteEmail(index) {
      // Eliminar el correo de la lista
      this.agency.email_configs.splice(index, 1)
    },
  },
  setup() {
    const agency = ref(null)
    const logo = ref(new MultipleMultimedia('multimedia', 'Logo de la agencia', [], Multimedia).setLimit(1))
    const addEmail = () => {
      agency.value.email_configs.push({ email: '' })
    }
    const systemTemplates = ref(null)
    const templates = ref({})
    const fields = [
      { key: 'notification', label: 'Notificación' },
      { key: 'email', label: 'Template ID' },
    ]

    GeneralService.getInstance().all('agency').then(result => {
      agency.value = result
      logo.value.setValue([result.logo])
      if (!agency.value.email_configs) {
        agency.value.email_configs = []
      }
      if (agency.value.email_configs.length === 0) {
        addEmail()
      }

      SystemTemplate.where('id', '>', 0).get().then(models => {
        systemTemplates.value = models

        systemTemplates.value.forEach(template => {
          templates.value[template.getAttr('id')] = ''
        })

        agency.value.agency_templates.forEach(template => {
          templates.value[template.system_template_id] = template.sendgrid_template_id
        })
      })
    })

    return {
      agency,
      logo,
      addEmail,
      systemTemplates,
      fields,
      templates,
    }
  },
}
</script>

<style scoped>

</style>
